<script setup lang="ts">
const { user, clear } = useUserSession()

const items = [
  [
    {
      label: 'Odhlásit',
      icon: 'i-heroicons-arrow-left-on-rectangle',
      click: clear,
    },
  ],
]
</script>

<template>
  <div>
    <div
      class="container mb-5 flex flex-wrap justify-between gap-x-5 gap-y-2 py-10"
    >
      <NuxtLink to="/admin">
        <img class="max-h-8 w-full" src="/img/logo.svg" />
      </NuxtLink>
      <UDropdown v-if="user" :items="items">
        <UButton
          color="white"
          trailing-icon="i-heroicons-chevron-down-20-solid"
        >
          <UAvatar :alt="user.name" size="3xs" />
          {{ user.name }}
        </UButton>
      </UDropdown>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>
